import { useHistory } from "react-router-dom";
import React, { useEffect, useState, forwardRef } from "react";
import { useSelector } from "react-redux";
// import './table.css'
import CollapsedPanel from "../../../../shared/components/CollapsedPanel";
import { useTranslation } from "react-i18next";
import { Button, Container } from "reactstrap";
import MaterialTable from "material-table";
import axios from "axios";
import store from "../../../App/store";
import { setClaimModeAction } from "../../../../redux/actions/claimAction";
import { setWithdrawClaimModeAction } from "../../../../redux/actions/withdrawClaimAction";
import {
  setCloseClaimModeAction,
  setCloseClaimDataAction
} from "../../../../redux/actions/closeClaimAction";
import DialogBoxMenu from "../../../../shared/components/DialogBoxMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Search from "@mui/icons-material/Search";
import FilterList from "@mui/icons-material/FilterList";
import { dashboardButtonsUIGuard as buttonGuard } from "../../../../shared/components/auth/UIGuard";

const dateFields = [
  "dateOfInjury",
  "certificateValidTo",
  "changedOn",
  "createdOn"
];
const tableIcons = {
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />)
};

const LabelPanelDivider = () => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const [popupData, setPopupData] = useState([
    "Update Claim",
    "Withdraw Claim",
    "View Claim",
    "Close Claim"
  ]);
  const [claimData, updateClaimData] = useState({});
  const [data, setData] = useState([]);
  const history = useHistory();
  const [calcFilter, setCalcFilter] = useState(["Active"]);
  const userData = useSelector((state) => state.user.user);

  const userRoles = localStorage.getItem("ACCROLES")
    ? localStorage.getItem("ACCROLES")
    : "";
  const isMoreVisible = userRoles
    .split(",")
    .filter((val) => buttonGuard.viewClaim.includes(val));
  const updateClaim = (rowData, type) => {
    if (type === "Update Claim") {
      store.dispatch(setClaimModeAction("UPDATE"));
      store.dispatch(setCloseClaimDataAction({}));
      history.push({
        pathname: "/forms/redux_form/wizard_form",
        state: {
          rowData: rowData,
          update: true,
          from: "/tables/data_table",
          type: "Update",
          roles: buttonGuard.updateClaim,
        },
      });
    } else if (type === "Withdraw Claim") {
      store.dispatch(setWithdrawClaimModeAction("WITHDRAW"));
      store.dispatch(setCloseClaimDataAction({}));
      history.push({
        pathname: "/forms/redux_form/wizard_form",
        state: {
          rowData: rowData,
          type: "Withdraw",
          from: "/tables/data_table",
          roles: buttonGuard.withdrawClaim,
        },
      });
    } else if (type === "View Claim") {
      store.dispatch(setClaimModeAction("VIEW"));
      store.dispatch(setCloseClaimDataAction({}));
      history.push({
        pathname: "/forms/redux_form/wizard_form",
        state: {
          rowData: rowData,
          type: "View",
          from: "/tables/data_table",
          roles: buttonGuard.viewClaim,
        },
      });
    } else if (type === "Close Claim") {
      store.dispatch(setCloseClaimModeAction("CLOSE"));
      store.dispatch(setWithdrawClaimModeAction(""));
      history.push({
        pathname: "/forms/redux_form/wizard_form",
        state: {
          rowData: rowData,
          type: "Close",
          from: "/tables/data_table",
          roles: buttonGuard.closeClaim,
        },
      });
    }
  };

  const getDefaultFilter = (field) => {
    const filters = JSON.parse(localStorage.getItem("filters"));
    for (var el in filters) {
      if (filters[el].column.field === field) {
        return filters[el].value;
      }
    }
    return "";
  };
  let calc_filter = getDefaultFilter("calculationStatus");
  const columns = [
    {
      title: "Calculation Status",
      field: "calculationStatus",
      editable: "never",
      lookup: {
        Active: "Active",
        PendingClosure: "Pending Closure",
        Closed: "Closed",
        Withdrawn: "Withdrawn",
      },
      defaultFilter: (calc_filter && calc_filter.length)?calc_filter:calcFilter,
    },
    {
      title: "Employee Number",
      field: "employeeNumber",
      editable: "never",
      defaultFilter: getDefaultFilter("employeeNumber"),
      customSort: (a, b) => a.employeeNumber - b.employeeNumber
    },
    {
      title: "ID",
      field: "id",
      editable: "never",
      defaultFilter: getDefaultFilter("id"),
      hidden: true
    },
    {
      title: "Employee First Name",
      field: "employeeFirstName",
      editable: "never",
      defaultFilter: getDefaultFilter("employeeFirstName")
    },
    {
      title: "Employee Last Name",
      field: "employeeLastName",
      editable: "never",
      defaultFilter: getDefaultFilter("employeeLastName")
    },
    {
      title: "Security Code",
      field: "payCategory",
      editable: "never",
      defaultFilter: getDefaultFilter("payCategory")
    },
    {
      title: "ACC Claim Number",
      field: "claimNumber",
      editable: "never",
      defaultFilter: getDefaultFilter("claimNumber")
    },
    {
      title: "Work/Non Work",
      field: "workRelated",
      editable: "never",
      defaultFilter: getDefaultFilter("workRelated"),
      render: (rowData) => {
        if (rowData.workRelated === "W") return "Work";
        else if (rowData.workRelated === "N") return "Non-Work";
      },
    },
    {
      title: "DOFI/DOSI",
      field: "claimType",
      editable: "never",
      defaultFilter: getDefaultFilter("claimType")
    },
    {
      title: "Monitored By",
      field: "monitoredBy",
      lookup: { ACC: "ACC", WorkAon: "WorkAon" },
      defaultFilter: getDefaultFilter("monitoredBy")
    },
    {
      title: "Medical Cert Status",
      field: "accStatus",
      editable: "never",
      defaultFilter: getDefaultFilter("accStatus")
    },
    {
      title: "Appr. Status",
      field: "claimStatus",
      lookup: {
        "ERA Opt Out": "ERA Opt Out",
        "Awaiting decision": "Awaiting decision",
        "Claim accepted": "Claim accepted",
        "Claim declined": "Claim declined",
        "ACC 38 Sent": "ACC 38 Sent",
        "Received refund": "Received refund",
      },
      defaultFilter: getDefaultFilter("claimStatus")
    },
    {
      title: "DOFI/DOSI Date",
      field: "dateOfInjury",
      editable: "never",
      defaultFilter: getDefaultFilter("dateOfInjury"),
      customSort: (a, b) => {
        var aa =  a.dateOfInjury && a.dateOfInjury.split("/").reverse().join(""),
          bb = b.dateOfInjury && b.dateOfInjury.split("/").reverse().join("");
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      }
    },
    {
      title: "Medical Expiry Date",
      field: "certificateValidTo",
      editable: "never",
      defaultFilter: getDefaultFilter("certificateValidTo"),
      customSort: (a, b) => {
        var aa = a.certificateValidTo && a.certificateValidTo.split("/").reverse().join(""),
          bb = b.certificateValidTo && b.certificateValidTo.split("/").reverse().join("");
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      }
    },
    {
      title: "Pay Cycle",
      field: "payCycle",
      editable: "never",
      defaultFilter: getDefaultFilter("payCycle")
    },
    {
      title: "Creation Date",
      field: "createdOn",
      editable: "never",
      defaultFilter: getDefaultFilter("createdOn"),
      customSort: (a, b) => {
        var aa = a.createdOn && a.createdOn.split("/").reverse().join(""),
          bb = b.createdOn && b.createdOn.split("/").reverse().join("");
        return Number(aa) < Number(bb) ? -1 : Number(aa) > Number(bb) ? 1 : 0;
      }
    },
    {
      title: "Over/Under Payment",  
      field: "paymentVariance",
      editable: "never",
      defaultFilter: getDefaultFilter("paymentVariance"),
      render: (rowData) => {
        return rowData.paymentVariance === "X" ? "Yes" : "No";
      }
    },
    {
      title: "Last Change Date",
      field: "changedOn",
      editable: "never",
      defaultFilter: getDefaultFilter("changedOn"),
      render: (rowData) => {
        return rowData.changedOn ? rowData.changedOn : rowData.createdOn;
      },
      customSort: (a, b) => {
        var aa = a.changedOn && a.changedOn.split("/").reverse().join(""),
          bb = b.changedOn && b.changedOn.split("/").reverse().join("");
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      }
    },
    {
      title: "Last Change By",
      field: "changedBy",
      editable: "never",
      defaultFilter: getDefaultFilter("changedBy"),
      render: (rowData) => {
        return rowData.changedBy ? rowData.changedBy : rowData.createdBy;
      }
    }
  ];

  const [downloadExcelProps, setDownloadExcelProps] = useState({
    type: "all",
    title: "test",
    showLabel: true,
  });

  useEffect(() => {
    fetchData();
    return () => {};
  }, [calcFilter]);

  useEffect(()=>{
    // clear visted rows in 30 mins
    setTimeout(() => localStorage.removeItem("userVisited"), 1800000);
  },[])

  const handleClickOpen = (flag, data) => {
    switch (data.calculationStatus) {
      case "Closed":
      case "Withdrawn":
        setPopupData(["View Claim"]);
        break;
      default:
        setPopupData([
          "Update Claim",
          "Withdraw Claim",
          "View Claim",
          "Close Claim",
        ]);
    }
    setOpen(flag);
    updateClaimData(data);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  function handleListItemClick(type) {
    updateClaim(claimData, type);
    setOpen(false);
    if(type === 'View Claim' || type === "Update Claim"){
      let vistedRows = JSON.parse(localStorage.getItem("userVisited"));
      vistedRows = (vistedRows)?vistedRows:[];
      vistedRows.push(claimData.id);
      localStorage.setItem("userVisited", JSON.stringify(vistedRows));  
    }
    //props.history.push("/forms/redux_form/wizard_form",{claimValue:value,claimDatas:claimData})
  }

  const handleRowUpdate = (newData, oldData, resolve) => {
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + `/claim/${oldData.id}/quickedit`,
        {
          claim: {
            id: newData.id,
            claimNumber: newData.claimNumber,
            claimStatus: newData.claimStatus,
            monitoredBy: newData.monitoredBy,
          },
        }
      )
      .then((resp) => {
        //Change data on UI on success
        oldData.changedBy =
          userData.idTokenClaims.given_name +
          " " +
          userData.idTokenClaims.family_name;
        oldData.changedOn = convertDate("today");
        oldData.monitoredBy = newData.monitoredBy;
        oldData.claimStatus = newData.claimStatus;
        resolve();
      })
      .catch((err) => {
        alert(`Unable to update claim, details: ${err.message}`);
        console.log(err);
        resolve();
      });
  };

  function convertDate(date) {
    // when passing today return todays date
    if (!date && date != "today") return date;
    let dateToReturn;
    const dateObject = date && date != "today" ? new Date(date) : new Date();
    const dd = String(dateObject.getDate()).padStart(2, "0");
    const mm = String(dateObject.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = dateObject.getFullYear();
    dateToReturn = dd + "/" + mm + "/" + yyyy;
    return dateToReturn;
  }

  const handleFilterChange = (filters) => {
    localStorage.setItem("filters", JSON.stringify(filters));
    const calcStatus = filters.filter(
      (row) => row.column.field === "calculationStatus"
    )[0].value;
    if (calcStatus === calcFilter) return;
    setCalcFilter(calcStatus);
  };

  const fetchData = async () => {
    let URL = process.env.REACT_APP_API_BASE_URL + "/claim?";
    for (const i of calcFilter) URL += "calculationStatus=" + i + "&";
    await axios
      .get(URL.slice(0, -1))
      .then((response) => {
        // check if the data is populated
        response.data.forEach((value, index, arr) => {
          dateFields.forEach((dateFieldName) => {
            if (value[dateFieldName]) {
              let date = value[dateFieldName];
              arr[index][dateFieldName] = convertDate(date);
            }
          });
        });
        if (response.data) setData(response.data);
        // you tell it that you had the result
      })
      .catch((response) => {
        alert(`Error fetching data, details: ${response.message}`);
        console.log(response);
      });
  };

  function fetchFilteredData(e) {
    setCalcFilter([e.target.id]);
  }

  const editDownloadProps = (e) => {
    let newProps = { ...downloadExcelProps };

    newProps[e.target.name] = e.target.value;
    setDownloadExcelProps(newProps);
  };

  return (
    <Container>
      <CollapsedPanel
        divider
        // title={t("ui_elements.panels.default_panel")}
        title="ACCelerator - Dashboard"
        // label="Table"
      >
        <Button
          color="primary"
          type="button"
          style={{ marginBottom: 10 }}
          onClick={fetchFilteredData}
          id="Active"
        >
          Reset
        </Button>
        <MaterialTable
          icons={tableIcons}
          title=""
          columns={columns}
          data={data}
          onFilterChange={(filters) => handleFilterChange(filters)}
          options={{
            headerStyle: { backgroundColor: "#213e8c", color: "#FFF" },
            filtering: true,
            sorting: true,
            search: true,
            exportButton: true,
            exportAllData: true,
            pageSize:
              data.length / 3 < 10 ? 10 : Math.ceil(data.length / 3 / 5) * 5,
            pageSizeOptions: [
              5,
              10,
              20,
              50,
              { value: data.length, label: "All" }, //You will see an error in console about this, ignore the warning, because the rendered component works
            ],
            doubleHorizontalScroll: true,
            showEmptyDataSourceMessage: true,
            rowStyle:(rowData) => {
              if(JSON.parse(localStorage.getItem("userVisited")) 
                && JSON.parse(localStorage.getItem("userVisited")).length
                && JSON.parse(localStorage.getItem("userVisited").includes(rowData.id))){
                  return { backgroundColor: "#EEE" };
              }
            },
          }}
          actions={[
            {
              icon: MoreHorizIcon,
              tooltip: "More",
              onClick: (event, rowData) => handleClickOpen(true, rowData),
              disabled: !isMoreVisible.length,
            },
          ]}
          editable={{
            isEditable: (rowData) =>
              Boolean(
                buttonGuard.edit.filter((role) => userRoles.includes(role))
                  .length
              ) && isMoreVisible.length,
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                handleRowUpdate(newData, oldData, resolve);
              }),
          }}
        />
      </CollapsedPanel>
      <DialogBoxMenu
        data={popupData}
        handleListItemClick={(value) => handleListItemClick(value)}
        handleClose={handleClose}
        open={open}
      />
    </Container>
  );
};

export default LabelPanelDivider;
